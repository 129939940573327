import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
  routes: [
    {
      name: '首页',
      path: '/index',
      component: () => import('@/pages/index.vue'),
    },
    {
      path: '/',
      redirect: 'index',
    },
    // {
    //   path: '/home',
    //   component: () => import('@/pages/home.vue'),
    // },
  ],
})
