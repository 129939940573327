<template>
  <div id="app" class="iconfont">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'App',
  data() {
    return {}
  },
  computed: {
    ...mapState(['themeColor']),
  },
  created() {
    this.changeColor
  },
  methods: {
    changeColor() {
      let str = ''
      let keyArr = Object.keys(this.themeColor)
      keyArr.forEach(item => {
        this.themeColor[item].forEach((ele, index) => {
          str = str + `--${item}${index}: ${ele};`
        })
      })
      window.document.getElementsByTagName('body')[0].setAttribute('style', str)
    },
  },
}
</script>
<style lang="scss" scoped>
#app::v-deep {
  i {
    font-style: normal;
  }
  // 滚动条整体部分
  ::-webkit-scrollbar {
    width: 0.075rem;
    height: 0.075rem;
  }
  // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
  ::-webkit-scrollbar-button {
    display: none;
  }
  // 滚动条的轨道（里面装有Thumb）
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  // 滚动条的轨道（里面装有Thumb）
  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
  ::-webkit-scrollbar-thumb {
    background: var(--App0);
    cursor: pointer;
    border-radius: 0.075rem;
  }
  // 边角，即两个滚动条的交汇处
  ::-webkit-scrollbar-corner {
    display: none;
  }
  // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
  ::-webkit-resizer {
    display: none;
  }
}
</style>
<style lang="scss">
// 右上提示弹窗
body {
  .el-notification {
    border: none;
    border-radius: 0.0625rem;
    background-color: var(--App1);
    box-shadow: 0.0125rem 0.0125rem 0.0625rem 0 rgba(0, 0, 0, 0.25);
    .el-notification__title,
    .el-notification__content,
    .el-notification__closeBtn {
      color: var(--App2);
    }
    .el-notification__icon {
      &.el-icon-success {
        color: #2ac840;
      }
      &.el-icon-warning {
        color: #fdbc2e;
      }
      &.el-icon-error {
        color: #ff5f57;
      }
    }
  }
}

// .el-message {
//   font-size: 0.25rem;
// }
// .el-message-box__wrapper .confirm {
//   width: 5.25rem;
//   padding-bottom: 0.375rem;
//   border-radius: 0.05rem;
//   font-size: 0.225rem;
//   box-shadow: 0 0.025rem 0.15rem 0 rgba(0, 0, 0, 0.1);
//   background-color: #edf3ff;
//   .el-message-box__header {
//     padding: 0.1875rem 0.1875rem 0.125rem;
//     .el-message-box__title {
//       font-size: 0.225rem;
//     }
//     .el-message-box__headerbtn {
//       top: 0.1875rem;
//       right: 0.1875rem;
//       font-size: 0.2rem;
//     }
//   }
//   .el-message-box__content {
//     padding: 0.125rem 0.3375rem;
//     font-size: 0.175rem;
//   }
//   .el-message-box__btns {
//     padding: 0.0625rem 0.1875rem 0;
//     .el-button {
//       font-size: 0.175rem;
//       padding: 0;
//       width: 0.625rem;
//       height: 0.3rem;
//       line-height: 0.3rem;
//       border-radius: 0.05rem;
//       border: none;
//       cursor: pointer;
//       color: #fff;
//       background: #014fff;
//       box-shadow: 0.025rem 0.025rem 0.05rem rgba(0, 0, 0, 0.25);
//       &:first-child {
//         color: #014fff;
//         background: #fff;
//       }
//     }
//   }
// }
</style>
